<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Product</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <b-card>
          <b-tabs v-model="tabIndex" fill>
            <b-tab title="Information" active>
              <validation-observer ref="productForm" #default="{ invalid }">
                <b-form @submit.prevent="onSubmit">
                  <Alert :error="errors" :success="success" />

                  <b-row>
                    <b-col>
                      <h2>Product Information</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Name" label-for="v-name">
                        <validation-provider
                          #default="{ errors }"
                          name="Product Name"
                          rules="required"
                        >
                          <b-form-input
                            :class="errors.length > 0 ? 'is-invalid' : null"
                            :state="errors.length > 0 ? false : null"
                            v-model="formData.name"
                            id="v-name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Category" label-for="v-category">
                        <validation-provider
                          #default="{ errors }"
                          name="Category"
                          rules="required"
                        >
                          <v-select
                            id="v-category"
                            label="name"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="formData.category_id"
                            :options="categories"
                            :reduce="(categories) => +categories.id"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Manufacturer"
                        label-for="v-manufacturer"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Manufacturer"
                          rules="required"
                        >
                          <v-select
                            id="v-manufacturer"
                            label="name"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="formData.manufacturer_id"
                            :options="manufacturers"
                            :reduce="(manufacturers) => +manufacturers.id"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="SKU" label-for="v-sku">
                        <b-form-input v-model="formData.sku" id="v-sku" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Barcode" label-for="v-barcode">
                        <b-form-input
                          v-model="formData.barcode"
                          id="v-barcode"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Price" label-for="v-price">
                        <cleave
                          id="v-price"
                          placeholder="0.00"
                          v-model="formData.preview_price"
                          class="form-control text-right"
                          :raw="false"
                          :options="{
                            numeral: true,
                            numeralThousandsGroupStyle: 'thousand',
                          }"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="New Price" label-for="v-new_price">
                        <cleave
                          id="v-new_price"
                          placeholder="0.00"
                          v-model="formData.preview_new_price"
                          class="form-control text-right"
                          :raw="false"
                          :options="{
                            numeral: true,
                            numeralThousandsGroupStyle: 'thousand',
                          }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="UOM" label-for="v-uom">
                        <b-form-input v-model="formData.uom" id="v-uom" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Brand" label-for="v-brand">
                        <v-select
                          id="v-brand"
                          label="name"
                          v-model="formData.brand"
                          :options="brands"
                          :reduce="(brands) => brands.name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Model No." label-for="v-model">
                        <b-form-input v-model="formData.model" id="v-model" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Stock Quantity"
                        label-for="v-stock_quantity"
                      >
                        <b-form-input
                          type="number"
                          class="text-right"
                          v-model.number="formData.stock_quantity"
                          id="v-stock_quantity"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="No. Of Servicing"
                        label-for="v-no_of_servicing"
                      >
                        <b-form-input
                          type="number"
                          v-model.number="formData.no_of_servicing"
                          id="v-no_of_servicing"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Job Quantity"
                        label-for="v-job_quantity"
                      >
                        <b-form-input
                          type="number"
                          v-model.number="formData.job_quantity"
                          id="v-job_quantity"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Minimum Order Quantity"
                        label-for="v-min_order_quantity"
                      >
                        <b-form-input
                          type="number"
                          v-model.number="formData.min_order_quantity"
                          id="v-min_order_quantity"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Maximum Order Quantity"
                        label-for="v-max_order_quantity"
                      >
                        <b-form-input
                          type="number"
                          v-model.number="formData.max_order_quantity"
                          id="v-max_order_quantity"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Product Type"
                        label-for="v-producttype"
                      >
                        <v-select
                          id="v-producttype"
                          label="name"
                          v-model.number="formData.product_type_id"
                          :options="productTypes"
                          :reduce="(productTypes) => +productTypes.id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Video Embed Code Link"
                        label-for="v-url"
                      >
                        <b-form-input v-model="formData.url" id="v-url" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Product Images</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <ProductImages
                          @set-default="(v) => onSetDefault(v)"
                          :data-source="dataSourceProductImages"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Description"
                        label-for="v-description"
                      >
                        <quill-editor
                          ref="quillEditorRef"
                          v-model="content"
                          id="v-description"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="$router.push(`/pages/catalogues/products`)"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        v-b-modal.tab-information
                        type="button"
                        variant="success"
                      >
                        Save
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-card no-body>
          <b-tabs v-model="tabIndexSection" fill>
            <b-tab active>
              <template #title>
                <small>Comments</small>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <small>Logs</small>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <small>Approval</small>
              </template>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import productApi from '@api/products';
  import categoriesApi from '@api/categories';
  import manufacturersApi from '@api/manufacturers';
  import brandsApi from '@api/brands';
  import { required } from '@validations';
  import { getImage } from '@/global-functions';
  import { queryParameters } from '@/schema';
  import ProductImages from './components/ProductImages';

  export default {
    name: 'ProductsManage',
    components: {
      ProductImages,
    },
    data: () => ({
      required,
      errors: {},
      success: false,
      dataIndex: -1,
      tabIndex: 0,
      openSection: false,
      productTypes: [
        { id: 1, name: 'Product' },
        { id: 0, name: 'Service' },
      ],
      categories: [],
      manufacturers: [],
      brands: [],
      dataSourceProductImages: [],
      content: '',

      formData: {
        name: '',
        product_type_id: 1,
        category_id: 0,
        manufacturer_id: 0,
        job_quantity: 0,
        sku: '',
        barcode: '',
        price: '',
        new_price: '',
        uom: '',
        brand: '',
        model: '',
        stock_quantity: 0,
        producttype: 1,
        url: '',
        description: '',
        no_of_servicing: 0,
        min_order_quantity: 0,
        max_order_quantity: 0,

        images: [],

        preview_price: '',
        preview_new_price: '',
      },
    }),
    mounted() {
      this.loadCategories();
      this.loadManufacturer();
      this.loadBrands();

      if (+this.$route.query.id) {
        this.loadProduct();
      }
    },
    methods: {
      onSetDefault(event) {
        for (let i = 0; i < this.dataSourceProductImages.length; i++) {
          const data = this.dataSourceProductImages[i];
          data.is_default = false;

          if (data.name === event.row.data.name) {
            data.is_default = true;
          }
        }
      },
      onSubmit() {
        this.$refs.productForm.validate().then((validateSuccess) => {
          if (validateSuccess) {
            const data = this.formData;
            data.price = +data.preview_price.replace(/,/g, '');
            data.new_price = +data.preview_new_price.replace(/,/g, '');

            const images = [];
            for (const image of this.dataSourceProductImages) {
              if (typeof image.id !== 'number') {
                image.reference_id = image.id;
              }

              images.push(image);

              if (image.is_default) {
                data.thumbnail = image.thumbnail;
              }
            }

            data.description = this.content;
            data.images = images;
            const mustHaveDefault = data.images.find((x) => {
              return x.is_default;
            });

            if (mustHaveDefault) {
              let app = null;
              if (!this.$route.query.id) {
                app = productApi.add(data);
              } else {
                app = productApi.update(data);
              }

              if (app) {
                app
                  .then(({ data }) => {
                    this.toastConfig();

                    const url = `/pages/catalogues/products/view?id=${data.id}`;
                    this.$router.push(url);
                  })
                  .catch(() => {
                    this.toastConfig('', 'error');
                  })
                  .finally(() => {
                    //
                  });
              }

              return;
            }

            return this.toastConfig(`Must have default product image`, 'error');
          }
        });
      },
      loadProduct() {
        productApi
          .get(this.$route.query.id)
          .then(({ data }) => {
            data.preview_price = data.price.toFixed(2);
            data.preview_new_price = data.new_price.toFixed(2);

            const { quill } = this.$refs.quillEditorRef;
            if (quill) {
              quill.root.innerHTML = data.description;
            }

            if (data.product_images.data) {
              for (const image of data.product_images.data) {
                const getImageData = {
                  model: 'products',
                  id: data.id,
                  thumbnail: image.thumbnail,
                };

                const item = {
                  id: image.id,
                  name: image.name,
                  description: image.description,
                  is_default: image.is_default,
                  thumbnail: image.thumbnail,
                  thumbnailb64: '',
                  url: getImage(getImageData),
                };

                this.dataSourceProductImages.push(item);
              }
            }

            this.formData = data;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadCategories() {
        const params = {
          sort: queryParameters.sort,
          page: queryParameters.page,
          status: queryParameters.status,
        };

        categoriesApi
          .list(params)
          .then(({ data }) => {
            this.categories = data;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadManufacturer() {
        const params = {
          sort: queryParameters.sort,
          page: queryParameters.page,
          status: queryParameters.status,
        };

        manufacturersApi
          .list(params)
          .then(({ data }) => {
            this.manufacturers = data;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadBrands() {
        const params = {
          sort: queryParameters.sort,
          page: queryParameters.page,
          status: queryParameters.status,
        };

        brandsApi
          .list(params)
          .then(({ data }) => {
            this.brands = data;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
    },
  };
</script>

<style></style>
